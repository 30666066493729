import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from './../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LinearProgress, linearProgressClasses, Box, Typography, Paper } from '@mui/material';
import { useTheme } from '../context/ThemeContext';
import { useTheme as useMuiTheme } from '@mui/material/styles';

import './Curso.css';

const BorderLinearProgress = styled(LinearProgress)(({ value }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#e0e0e0',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: (() => {
      if (value < 34) return '#FF0000';
      if (value < 70) return '#FFD700';
      return '#00FF00';
    })(),
  },
}));

const Curso = () => {
    const { idcurso } = useParams();
    const [cursos, setCursos] = useState([]);
    const [curso, setCurso] = useState(null);
    const navigate = useNavigate();
    const { isDarkMode } = useTheme();
    const muiTheme = useMuiTheme();

    const fetchCurso = async () => {
        try {
            const { data, error } = await supabase
                .from('cursos')
                .select('*')
                .eq('idcurso', idcurso)
                .single();
            
            if (error) throw error;
            setCurso(data);
        } catch (error) {
            console.error('Error al cargar el curso:', error.message);
        }
    };

    const fetchModulosPorCurso = async () => {
        try {
            const { data, error } = await supabase.rpc('get_modulos_por_curso', { p_idcurso: idcurso });
            if (error) throw error;
            setCursos(data);
        } catch (error) {
            console.error('Error al cargar los módulos del curso:', error.message);
        }
    };

    useEffect(() => {
        fetchCurso();
        fetchModulosPorCurso();
    }, [idcurso]);

    return (
        <Box 
            sx={{ 
                minHeight: 'calc(100vh - 64px)',
                bgcolor: 'background.default',
                color: 'text.primary',
                pt: 4,
                pb: 6,
                px: 3,
                mt: -1
            }}
        >
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center'
            }}>
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    mb: 4
                }}>
                    <span 
                        className="material-symbols-outlined btn-volver" 
                        onClick={() => navigate(-1)}
                        style={{ 
                            color: isDarkMode ? '#fff' : '#000',
                            cursor: 'pointer'
                        }}
                    >
                        reply
                    </span>
                    <Typography 
                        variant="h4" 
                        sx={{ 
                            ml: 2,
                            color: 'text.primary'
                        }}
                    >
                        {idcurso}
                    </Typography>
                    <Typography 
                        variant="h5" 
                        sx={{ 
                            ml: 2,
                            color: 'text.primary'
                        }}
                    >
                        {curso?.curso}
                    </Typography>
                </Box>
            </Box>

            <Box 
                className="tarjetas-container"
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '10px',
                    marginTop: '40px',
                    '& .card': {
                        width: '180px !important',
                        maxWidth: '180px !important',
                        borderRadius: '10px !important',
                        marginLeft: '10px !important',
                        marginRight: '10px !important',
                        '& figure': {
                            margin: 0,
                            padding: 0,
                            height: '100px',
                            overflow: 'hidden',
                            '& img': {
                                width: '90px',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '5px auto'
                            }
                        },
                        '& .card-body': {
                            padding: '5px !important',
                            textAlign: 'center',
                            fontSize: '14px',
                            '& h3': {
                                margin: '2px 0',
                                padding: '0 !important'
                            }
                        }
                    }
                }}
            >
                {cursos.map((curso, key) => (
                    <Paper
                        key={key}
                        onClick={() => navigate(`/cursos/${curso.idcurso}/${curso.url}`)}
                        elevation={isDarkMode ? 2 : 1}
                        className="card"
                        sx={{
                            bgcolor: 'background.paper',
                            color: 'text.primary',
                            cursor: 'pointer',
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                                transform: 'translateY(-4px)',
                                boxShadow: 6
                            }
                        }}
                    >
                        <h2 className='idcurso' style={{ 
                            color: curso?.color,
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            marginBottom: '0.5rem'
                        }}>
                            {idcurso}
                        </h2>
                        <h3 style={{
                            color: curso?.color,
                            textAlign: 'center',
                            fontSize: '1.2rem',
                            marginBottom: '1rem'
                        }}>
                            {curso?.modulo || 'Módulo no disponible'}
                        </h3>
                        <figure className="text-center">
                            <img 
                                src={`${curso?.url_portada}?v=${new Date().getTime()}`} 
                                alt={`Imagen del curso ${curso?.curso}`} 
                            />
                        </figure>
                        <div className="card-body">
                            <h3 style={{ height: '64px' }}>{curso?.curso}</h3>
                            <div style={{ marginTop: '30px' }}>
                                <BorderLinearProgress 
                                    variant="determinate" 
                                    value={curso?.promedio_porcentaje || 0}
                                />
                                <p style={{ 
                                    textAlign: 'center', 
                                    marginTop: '10px', 
                                    fontSize: '20px', 
                                    fontWeight: 'bold',
                                    color: (() => {
                                        const value = curso?.promedio_porcentaje || 0;
                                        if (value < 34) return '#FF0000';
                                        if (value < 70) return '#FFD700';
                                        return '#00FF00';
                                    })()
                                }}>
                                    {curso?.promedio_porcentaje || 0}%
                                </p>
                            </div>
                            <div style={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'flex-start', 
                                marginTop: '30px', 
                                paddingTop: '10px',
                                fontSize: '18px'
                            }}>
                                <span 
                                    className="material-symbols-outlined" 
                                    style={{ 
                                        marginRight: '5px',
                                        color: isDarkMode ? '#fff' : '#000'
                                    }}
                                >
                                    group
                                </span>
                                <span>{curso?.usuarios_unicos || 0} graduados</span>
                            </div>
                        </div>
                    </Paper>
                ))}
            </Box>
        </Box>
    );
};

export default Curso;