import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useTheme } from '../context/ThemeContext';

const Footer = () => {
    const { isDarkMode } = useTheme();
    const currentYear = new Date().getFullYear();

    return (
        <Box
            component="footer"
            sx={{
                bgcolor: isDarkMode ? '#1e1e1e' : '#ffffff',
                color: isDarkMode ? '#ffffff' : '#000000',
                py: 2,
                borderTop: 1,
                borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                mt: 'auto'
            }}
        >
            <Box
                sx={{
                    maxWidth: 1200,
                    mx: 'auto',
                    px: 3,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 2
                }}
            >
                <Typography variant="body2">
                    {currentYear} Plataforma VR. Todos los derechos reservados.
                </Typography>
                <Box sx={{ display: 'flex', gap: 3 }}>
                    <Link
                        href="#"
                        sx={{
                            color: 'inherit',
                            textDecoration: 'none',
                            '&:hover': {
                                color: '#e55302'
                            }
                        }}
                    >
                        Términos y Condiciones
                    </Link>
                    <Link
                        href="#"
                        sx={{
                            color: 'inherit',
                            textDecoration: 'none',
                            '&:hover': {
                                color: '#e55302'
                            }
                        }}
                    >
                        Política de Privacidad
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
