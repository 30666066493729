import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Paper, Button, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSupabaseClient } from '@supabase/auth-helpers-react'; 
import { useState, useEffect } from 'react'; 
import * as ExcelJS from 'exceljs'; 
import * as FileSaver from 'file-saver'; 
import { styled } from '@mui/material/styles';
import { useTheme } from '../context/ThemeContext';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import './Usuarios.css';

function hexToArgb(hex) {
    if (hex.startsWith('#')) {
        hex = hex.slice(1);
    }
    if (hex.length !== 6) {
        throw new Error('Formato de color hexadecimal no válido');
    }
    return `FF${hex.toUpperCase()}`; 
}

const TablaUsuarios = () => {
    const supabaseClient = useSupabaseClient();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uploadStatus, setUploadStatus] = useState(null); 
    const [singleUserUploadStatus, setSingleUserUploadStatus] = useState(null); 
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editingUser, setEditingUser] = useState({
        nombre: '',
        apellido: '',
        email: '',
        empresa: ''
    });
    const [newUser, setNewUser] = useState({
        nombre: '',
        apellido: '',
        email: '',
        empresa: ''
    });
    const [message, setMessage] = useState(''); 
    const [isCreating, setIsCreating] = useState(false); 
    const [isEditing, setIsEditing] = useState(false); 
    const { isDarkMode } = useTheme();
    const muiTheme = useMuiTheme();

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const { data, error } = await supabaseClient
                .from('usuarios')
                .select('*');

            if (error) throw error;

            const usersWithGraduationData = await Promise.all(data.map(async (user) => {
                const { data: graduationData, error: graduationError } = await supabaseClient
                    .from('graduados')
                    .select('idcurso, modulo, porcentaje, created_at') 
                    .eq('iduser', user.iduser)
                    .order('created_at', { ascending: true });
                    

                if (graduationError) {
                    console.error('Error al cargar los datos de graduados:', graduationError);
                    return { ...user, cursos: [] }; 
                }

                const cursos = graduationData.map(graduation => {
                    const puntajeFinal = graduation.porcentaje 
                        ? Math.floor(parseFloat(graduation.porcentaje.replace(',', '.'))) === 99 
                            ? 100 
                            : Math.floor(parseFloat(graduation.porcentaje.replace(',', '.'))) 
                        : 0;

                    return {
                        idcurso: graduation.idcurso,
                        modulo: graduation.modulo,
                        estado: puntajeFinal >= 99 ? 'Aprobado' : 'Reprobado',
                        porcentaje: puntajeFinal
                    };
                });

                return { ...user, cursos }; 
            }));

            setUsers(usersWithGraduationData);
        } catch (error) {
            console.error('Error al cargar los usuarios:', error);
            setUploadStatus('Error al cargar los usuarios');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [supabaseClient]);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        try {
            setUploadStatus('Cargando usuarios...');
            const response = await fetch('/.netlify/functions/createUsers', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Error en la respuesta del servidor');
            }

            const result = await response.json();

            if (result.error) {
                throw new Error(result.error);
            }

            setUploadStatus('Usuarios cargados correctamente');
            fetchUsers(); 
        } catch (error) {
            console.error('Error:', error);
            setUploadStatus(`Error al cargar el archivo: ${error.message}`);
        }
    };

    const handleViewProfile = (userId) => {
        navigate(`/usuario-perfil/${userId}`);
    };

    const filteredUsers = users.filter(user => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
            (user.nombre && user.nombre.toLowerCase().includes(searchTermLower)) ||
            (user.apellido && user.apellido.toLowerCase().includes(searchTermLower)) ||
            (user.email && user.email.toLowerCase().includes(searchTermLower))
        );
    });

    const capitalizeFirstLetter = (str) => {
        if (!str) return '';
        return str.toLowerCase().split(' ').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ).join(' ');
    };

    const columns = [
        { 
            field: 'nombre', 
            headerName: 'Nombre', 
            flex: 1,
            minWidth: 150,
            renderCell: (params) => capitalizeFirstLetter(params.value)
        },
        { 
            field: 'apellido', 
            headerName: 'Apellido', 
            flex: 1,
            minWidth: 150,
            renderCell: (params) => capitalizeFirstLetter(params.value)
        },
        { 
            field: 'email', 
            headerName: 'Email', 
            flex: 2,
            minWidth: 250,
            renderCell: (params) => (
                <div style={{ 
                    whiteSpace: 'normal', 
                    wordWrap: 'break-word', 
                    lineHeight: '1.2em',
                    padding: '16px 0',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {params.value.toLowerCase()}
                </div>
            )
        },
        { 
            field: 'empresa', 
            headerName: 'Empresa', 
            flex: 1,
            minWidth: 150,
            renderCell: (params) => capitalizeFirstLetter(params.value)
        },
        { 
            field: 'clave', 
            headerName: 'Clave', 
            flex: 1,
            minWidth: 120
        }
    ];

    if (loading) return <p>Cargando usuarios...</p>;

    const visibleRows = filteredUsers.slice(0, paginationModel.pageSize); 

    const exportVisibleToExcel = () => {
        const visibleRows = filteredUsers.slice(0, paginationModel.pageSize);

        if (visibleRows.length === 0) return;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Usuarios');

        const headers = [
            { header: 'Nombre', key: 'nombre', width: 20 },
            { header: 'Apellido', key: 'apellido', width: 20 },
            { header: 'Email', key: 'email', width: 30 },
            { header: 'Empresa', key: 'empresa', width: 15 },
            { header: 'Clave', key: 'clave', width: 15 },
            { header: 'Link', key: 'link', width: 70 },
        ];

        const uniqueHeaders = new Set();

        visibleRows.forEach(user => {
            user.cursos.forEach(curso => {
                const header = `${curso.idcurso} - ${curso.modulo}`;
                uniqueHeaders.add(header);
            });
        });

        Array.from(uniqueHeaders).forEach(header => {
            headers.push({ header, key: header.replace(/ /g, '_'), width: 20 });
        });

        worksheet.columns = headers;

        visibleRows.forEach(user => {
            const row = {
                nombre: user.nombre,
                apellido: user.apellido,
                email: user.email,
                empresa: user.empresa,
                clave: user.clave,
                link: { text: `https://plataformavr.cl/usuario-perfil/${user.iduser}`, hyperlink: `https://plataformavr.cl/usuario-perfil/${user.iduser}` },
            };

            user.cursos.forEach(curso => {
                const porcentajeKey = `${curso.idcurso} - ${curso.modulo}`.replace(/ /g, '_');
                row[porcentajeKey] = curso.porcentaje ? parseFloat(curso.porcentaje) / 100 : 0; 
            });

            const newRow = worksheet.addRow(row);

            user.cursos.forEach(curso => {
                const porcentajeKey = `${curso.idcurso} - ${curso.modulo}`.replace(/ /g, '_');
                const cell = newRow.getCell(porcentajeKey);
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.numFmt = '0%'; 

                if (curso.porcentaje >= 99) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: hexToArgb('#C6EFCE') } 
                    };
                    cell.font = {
                        color: { argb: hexToArgb('#006100') } 
                    };
                } else {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: hexToArgb('#FFC7CE') } 
                    };
                    cell.font = {
                        color: { argb: hexToArgb('#9C0006') } 
                    };
                }
            });
        });

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            const date = new Date();
            const formattedDate = `${date.getDate()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
            a.href = url;
            a.download = `usuarios-${formattedDate}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser({
            ...newUser,
            [name]: value
        });
    };

    const handleCreateUser = async () => {
        setIsCreating(true); 
        try {
            if (newUser.nombre.trim() === '') {
                setMessage('El nombre es obligatorio'); 
                setIsCreating(false);
                return;
            }
            if (newUser.nombre.length < 3 || !/^[a-zA-Z\s]+$/.test(newUser.nombre)) {
                setMessage('El nombre debe tener más de 3 caracteres y solo contener texto'); 
                setIsCreating(false);
                return;
            }
            if (newUser.apellido.trim() === '') {
                setMessage('El apellido es obligatorio'); 
                setIsCreating(false);
                return;
            }
            if (newUser.apellido.length < 3 || !/^[a-zA-Z\s]+$/.test(newUser.apellido)) {
                setMessage('El apellido debe tener más de 3 caracteres y solo contener texto'); 
                setIsCreating(false);
                return;
            }
            if (!validateEmail(newUser.email)) {
                setMessage('El email no es válido'); 
                setIsCreating(false);
                return;
            }

            setSingleUserUploadStatus('Creando usuario...'); 
            const response = await fetch('/.netlify/functions/createSingleUser', {
                method: 'POST',
                body: JSON.stringify(newUser),
            });

            if (!response.ok) {
                const errorResult = await response.json();
                throw new Error(errorResult.error || 'Error en la respuesta del servidor');
            }

            const result = await response.json();

            if (result.error && result.error.includes('usuario ya existe')) {
                setMessage('El email ya está en uso'); 
                setIsCreating(false);
                return;
            }

            setMessage('Usuario creado correctamente'); 
            setOpenDialog(false);
            
            setNewUser({
                nombre: '',
                apellido: '',
                email: '',
                empresa: ''
            });

            setTimeout(() => {
                setMessage('');
            }, 3000);

            fetchUsers(); 
        } catch (error) {
            console.error('Error:', error);
            setMessage(`Error al crear el usuario: ${error.message}`);
        } finally {
            setIsCreating(false); 
        }
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        setMessage(''); 
    };

    const handleEditUser = async () => {
        setIsEditing(true);
        try {
            if (editingUser.nombre.trim() === '') {
                setMessage('El nombre es obligatorio');
                setIsEditing(false);
                return;
            }
            if (editingUser.nombre.length < 3 || !/^[a-zA-ZÀ-ÿ\s]+$/u.test(editingUser.nombre)) {
                setMessage('El nombre debe tener más de 3 caracteres y solo contener texto');
                setIsEditing(false);
                return;
            }
            if (editingUser.apellido.trim() === '') {
                setMessage('El apellido es obligatorio');
                setIsEditing(false);
                return;
            }
            if (editingUser.apellido.length < 3 || !/^[a-zA-ZÀ-ÿ\s]+$/u.test(editingUser.apellido)) {
                setMessage('El apellido debe tener más de 3 caracteres y solo contener texto');
                setIsEditing(false);
                return;
            }

            const { data, error } = await supabaseClient
                .from('usuarios')
                .update({
                    nombre: capitalizeFirstLetter(editingUser.nombre),
                    apellido: capitalizeFirstLetter(editingUser.apellido),
                    empresa: capitalizeFirstLetter(editingUser.empresa)
                })
                .eq('email', editingUser.email.toLowerCase());

            if (error) throw error;

            setMessage('Usuario actualizado correctamente');
            setOpenEditDialog(false);
            fetchUsers();

            setTimeout(() => {
                setMessage('');
            }, 3000);

        } catch (error) {
            console.error('Error:', error);
            setMessage(`Error al actualizar el usuario: ${error.message}`);
        } finally {
            setIsEditing(false);
        }
    };

    const handleRowClick = (params) => {
        setSelectedUser(params.row);
        setEditingUser({
            nombre: capitalizeFirstLetter(params.row.nombre),
            apellido: capitalizeFirstLetter(params.row.apellido),
            email: params.row.email.toLowerCase(),
            empresa: capitalizeFirstLetter(params.row.empresa)
        });
        setOpenEditDialog(true);
        setMessage('');
    };

    return (
        <Box 
            className="page-container"
            sx={{ 
                bgcolor: isDarkMode ? 'background.default' : '#ffffff'
            }}
        >
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 3,
                gap: 2
            }}>
                <TextField
                    fullWidth
                    placeholder="Buscar por nombre, apellido o email..."
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <span className="material-symbols-outlined">search</span>
                            </InputAdornment>
                        ),
                        sx: {
                            bgcolor: isDarkMode ? '#1e1e1e' : '#ffffff',
                            color: isDarkMode ? '#ffffff' : '#000000',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'
                            }
                        }
                    }}
                />
                <Button
                    variant="contained"
                    onClick={() => setOpenDialog(true)}
                    sx={{
                        bgcolor: '#e55302',
                        minWidth: '180px',
                        height: '45px',
                        '&:hover': {
                            bgcolor: '#b23d00'
                        }
                    }}
                >
                    NUEVO USUARIO
                </Button>
            </Box>

            <Box sx={{ 
                width: '100%',
                backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff',
                borderRadius: 1,
                overflow: 'hidden'
            }}>
                <DataGrid
                    rows={visibleRows}
                    columns={columns}
                    getRowId={(row) => row.iduser}
                    autoHeight
                    disableRowSelectionOnClick
                    pageSizeOptions={[10, 25, 50]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    onRowClick={handleRowClick}
                    sx={{
                        width: '100%',
                        border: 'none',
                        backgroundColor: 'transparent',
                        '& .MuiDataGrid-cell': {
                            color: isDarkMode ? '#ffffff' : '#000000',
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                            backgroundColor: 'transparent'
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: isDarkMode ? '#2d2d2d !important' : '#f5f5f5 !important',
                            color: isDarkMode ? '#ffffff' : '#000000',
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'
                        },
                        '& .MuiDataGrid-row': {
                            backgroundColor: 'transparent !important',
                            '&:hover': {
                                backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05) !important' : 'rgba(0, 0, 0, 0.05) !important'
                            }
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: isDarkMode ? '#2d2d2d !important' : '#f5f5f5 !important',
                            borderTop: isDarkMode ? '1px solid rgba(255, 255, 255, 0.12)' : '1px solid rgba(0, 0, 0, 0.12)'
                        },
                        '& .MuiTablePagination-root': {
                            color: isDarkMode ? '#ffffff' : '#000000'
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            minHeight: '400px',
                            backgroundColor: 'transparent'
                        },
                        '& .MuiDataGrid-main, & .MuiDataGrid-virtualScrollerContent, & .MuiDataGrid-virtualScrollerRenderZone, & .MuiDataGrid-overlay': {
                            backgroundColor: 'transparent !important'
                        }
                    }}
                />
            </Box>

            <Box sx={{ 
                width: '100%', 
                display: 'flex',
                justifyContent: 'space-between',
                mt: 3,
                gap: 2
            }}>
                <Button
                    variant="outlined"
                    component="label"
                    sx={{
                        minWidth: '180px',
                        height: '45px',
                        color: '#e55302',
                        borderColor: '#e55302',
                        bgcolor: 'transparent',
                        '&:hover': {
                            bgcolor: '#e55302',
                            color: '#ffffff',
                            borderColor: '#e55302'
                        }
                    }}
                >
                    CARGAR USUARIOS
                    <input
                        type="file"
                        hidden
                        accept=".xlsx,.xls"
                        onChange={handleFileUpload}
                    />
                </Button>

                <Button
                    variant="outlined"
                    onClick={exportVisibleToExcel}
                    sx={{
                        minWidth: '180px',
                        height: '45px',
                        color: '#e55302',
                        borderColor: '#e55302',
                        bgcolor: 'transparent',
                        '&:hover': {
                            bgcolor: '#e55302',
                            color: '#ffffff',
                            borderColor: '#e55302'
                        }
                    }}
                >
                    EXPORTAR A EXCEL
                </Button>
            </Box>

            <Dialog 
                open={openDialog} 
                onClose={() => setOpenDialog(false)}
                PaperProps={{
                    sx: {
                        bgcolor: 'background.paper',
                        color: 'text.primary'
                    }
                }}
            >
                <DialogTitle>Crear Nuevo Usuario</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            sx={{
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: 'text.primary',
                                }
                            }}
                            fullWidth
                            label="Nombre"
                            name="nombre"
                            value={newUser.nombre}
                            onChange={handleInputChange}
                        />
                        <TextField
                            sx={{
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: 'text.primary',
                                }
                            }}
                            fullWidth
                            label="Apellido"
                            name="apellido"
                            value={newUser.apellido}
                            onChange={handleInputChange}
                        />
                        <TextField
                            sx={{
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: 'text.primary',
                                }
                            }}
                            fullWidth
                            label="Email"
                            name="email"
                            value={newUser.email}
                            onChange={handleInputChange}
                        />
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: 'text.primary',
                                }
                            }}
                            fullWidth
                            label="Empresa"
                            name="empresa"
                            value={newUser.empresa}
                            onChange={handleInputChange}
                        />
                    </Box>
                    {message && (
                        <Box sx={{ 
                            mt: 2, 
                            color: message.includes('Error') ? 'error.main' : 'success.main',
                            textAlign: 'center'
                        }}>
                            {message}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    <Button onClick={handleCreateUser} disabled={isCreating}>
                        {isCreating ? 'Creando...' : 'Crear'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog 
                open={openEditDialog} 
                onClose={() => setOpenEditDialog(false)}
                PaperProps={{
                    sx: {
                        bgcolor: 'background.paper',
                        color: 'text.primary'
                    }
                }}
            >
                <DialogTitle>Editar Usuario</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            sx={{
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: 'text.primary',
                                }
                            }}
                            fullWidth
                            label="Nombre"
                            name="nombre"
                            value={editingUser.nombre}
                            onChange={(e) => setEditingUser({ ...editingUser, nombre: e.target.value })}
                        />
                        <TextField
                            sx={{
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: 'text.primary',
                                }
                            }}
                            fullWidth
                            label="Apellido"
                            name="apellido"
                            value={editingUser.apellido}
                            onChange={(e) => setEditingUser({ ...editingUser, apellido: e.target.value })}
                        />
                        <TextField
                            sx={{
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: 'text.primary',
                                }
                            }}
                            fullWidth
                            label="Email"
                            name="email"
                            value={editingUser.email}
                            disabled
                        />
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    color: 'text.primary',
                                }
                            }}
                            fullWidth
                            label="Empresa"
                            name="empresa"
                            value={editingUser.empresa}
                            onChange={(e) => setEditingUser({ ...editingUser, empresa: e.target.value })}
                        />
                    </Box>
                    {message && (
                        <Box sx={{ 
                            mt: 2, 
                            color: message.includes('Error') ? 'error.main' : 'success.main',
                            textAlign: 'center'
                        }}>
                            {message}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setOpenEditDialog(false)}
                        sx={{
                            color: '#e55302',
                            '&:hover': {
                                bgcolor: 'rgba(229, 83, 2, 0.04)'
                            }
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleEditUser} 
                        disabled={isEditing}
                        sx={{
                            bgcolor: '#e55302',
                            color: '#ffffff',
                            '&:hover': {
                                bgcolor: '#b23d00'
                            },
                            '&:disabled': {
                                bgcolor: 'rgba(229, 83, 2, 0.5)',
                                color: '#ffffff'
                            }
                        }}
                    >
                        {isEditing ? 'Guardando...' : 'Guardar'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TablaUsuarios;