import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { createClient } from '@supabase/supabase-js';
import { Box } from '@mui/material';
import VistaModulo from './pages/VistaModulo';
import Home from './pages/Home';
import './index.css'
import NotFound from './pages/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import VistaCertificado from './pages/VistaCertificado';
import Logout from './pages/Logout';
import UsuarioPerfil from './pages/UsuarioPerfil';
import VistaResultado from './pages/VistaResultado';
import Header from './objetos/Header';
import Footer from './objetos/Footer';
import { UserContextProvider } from './objetos/UserContext';
import { ThemeProvider } from './context/ThemeContext';
import { BrightnessProvider } from './context/BrightnessContext';
import Cursos from './pages/Cursos';
import Curso from './pages/Curso';
import Usuarios from './pages/Usuarios';
import Soporte from './pages/Soporte';
import MiPerfil from './pages/MiPerfil';
import './App.css';

// Configuración de Supabase
const supabaseUrl = import.meta.env.VITE_SB_URL;
const supabaseKey = import.meta.env.VITE_SB_KEY;
const supabaseClient = createClient(supabaseUrl, supabaseKey);

console.log(supabaseClient);

const Layout = () => {
  return (
    <>
      <Header />
      <Box sx={{ 
        minHeight: 'calc(100vh - 64px - 50px)', // Altura total - header - footer
        backgroundColor: theme => theme.palette.mode === 'dark' ? '#121212' : '#ffffff',
        transition: 'background-color 0.3s ease',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Outlet />
      </Box>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <BrightnessProvider>
          <UserContextProvider>
            <Routes>
            <Route path="/" element={<Home />} />
            <Route element={<Layout />}>
              <Route path="/cursos" element={
                <ProtectedRoute>
                  <Cursos />
                </ProtectedRoute>
              } />

              <Route path="/cursos/:idcurso" element={
                <ProtectedRoute>
                  <Curso />
                </ProtectedRoute>
              } />

              <Route path="/cursos/:idcurso/:url" element={
                <ProtectedRoute>
                  <VistaModulo />
                </ProtectedRoute>
              } />

              <Route path="/Certificado" element={<VistaCertificado />} />

              <Route path="/usuario-perfil/:userId" element={
                <ProtectedRoute>
                  <UsuarioPerfil />
                </ProtectedRoute>
              } />
              <Route path="/usuario-perfil/:userId/RC09/:intento" element={
                <ProtectedRoute>
                  <VistaResultado isFromDashboard={false} />
                </ProtectedRoute>
              } />

              <Route path="/usuarios/" element={
                <ProtectedRoute>
                  <Usuarios />
                </ProtectedRoute>
              } />

              <Route path="/soporte" element={
                <ProtectedRoute>
                  <Soporte />
                </ProtectedRoute>
              } />

              <Route path="/mi-perfil" element={
                <ProtectedRoute>
                  <MiPerfil />
                </ProtectedRoute>
              } />

              <Route path="/salir" element={<Logout />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </UserContextProvider>
      </BrightnessProvider>
    </ThemeProvider>
  </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SessionContextProvider supabaseClient={supabaseClient}>
      <App />
    </SessionContextProvider>
  </React.StrictMode>
);