import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";
import Divider from '../objetos/Divider';
import './VistaModulo.css';
import { useTheme } from '@mui/material/styles';
import VistaCertificado from './VistaCertificado';
import html2pdf from 'html2pdf.js';
import { createRoot } from 'react-dom/client'; 
import VistaResultado from './VistaResultado';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme as useAppTheme } from '../context/ThemeContext';
import { Box, Paper, Typography, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ReplyIcon from '@mui/icons-material/Reply';

const VistaModulo = ({ currentUserSelected, usuario }) => {
  const minWidthPx = 350;
  const minWidthPercent = minWidthPx / window.innerWidth * 100;
  const [leftPanelWidth, setLeftPanelWidth] = useState(() => {
    const savedWidth = localStorage.getItem('leftPanelWidth');
    return savedWidth ? Math.max(parseFloat(savedWidth), minWidthPercent) : 50;
  });
  const [rightPanelWidth, setRightPanelWidth] = useState(window.innerWidth - leftPanelWidth * window.innerWidth / 100);

  const [scormUsers, setScormUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const supabaseClient = useSupabaseClient();
  const user = useUser();
  const [percentageValue, setPercentageValue] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const theme = useTheme();
  const [isInactive, setIsInactive] = useState(false);
  const navigate = useNavigate();
  const { idcurso, url } = useParams();
  console.log('idcurso:', idcurso, 'url:', url);
  
  // console.log('Usuario:', usuario);

  const loadScormUsers = useCallback(async () => {
    if (!user) return;

    try {
      // Obtener el módulo usando la url y el idcurso
      const { data: moduloData, error: moduloError } = await supabaseClient
        .from('modulos')
        .select('modulo')
        .eq('url', url)
        .eq('idcurso', idcurso)
        .single();

      if (moduloError) throw moduloError;

      const modulo = moduloData.modulo;

      // Obtener datos de graduados filtrados por idcurso y modulo
      const { data: scormData, error: scormError } = await supabaseClient
        .from('graduados')
        .select('*')
        .eq('idcurso', idcurso)
        .eq('modulo', modulo)
        .order('created_at', { ascending: false });

      if (scormError) throw scormError;

      // Obtener los iduser únicos de los datos de graduados_rc09
      const userIds = scormData.map(user => user.iduser);

      // Consulta para obtener los datos de los usuarios basados en iduser
      const { data: userData, error: userError } = await supabaseClient
        .from('usuarios')
        .select('iduser, nombre, apellido')
        .in('iduser', userIds);

      if (userError) throw userError;
      // console.log('Datos de usuarios:', userData);

      // Mapeamos los datos para agregar los nombres, apellidos y empresa a los usuarios SCORM
      const mergedData = scormData.map(scormUser => {
        const userDataForScormUser = userData.find(user => user.iduser === scormUser.iduser);
        return {
          ...scormUser,
          nombre_usuario: userDataForScormUser ? userDataForScormUser.nombre : null,
          apellido_usuario: userDataForScormUser ? userDataForScormUser.apellido : null,
        };
      });

      setScormUsers(mergedData);

      if (mergedData.length > 0) {
        const firstUser = mergedData[0];
        setSelectedUser(firstUser);
        setSelectedUserId(firstUser.id);
      }
    } catch (error) {
      console.error('Error al cargar los usuarios SCORM:', error);
    }
  }, [user, supabaseClient, idcurso, url]); // Dependencias de useCallback


  useEffect(() => {
    if (user) {
      loadScormUsers();
    }
  }, [user, loadScormUsers]);// Ahora loadScormUsers es una dependencia

  useEffect(() => {
    if (selectedUser && selectedUser.porcentaje) {
      // Elimina el símbolo de porcentaje y convierte a número flotante
      const numericPercentage = parseFloat(selectedUser.porcentaje.replace('%', ''));
      setPercentageValue(numericPercentage); // Establece el valor en el estado
    }
  }, [selectedUser]);
  
  useEffect(() => {
    // Actualizar el ancho del panel derecho
    const updateRightPanelWidth = () => {
      setRightPanelWidth(window.innerWidth - leftPanelWidth * window.innerWidth / 100);
    };
    
    window.addEventListener('resize', updateRightPanelWidth);
    updateRightPanelWidth();
    return () => window.removeEventListener('resize', updateRightPanelWidth);
  }, [leftPanelWidth]);

   const handleDrag = (newWidth) => {
     // Convertir el nuevo ancho en porcentaje del ancho de la ventana del navegador
    const widthPercent = newWidth / window.innerWidth * 100;
    // Actualizar el estado con el nuevo porcentaje, asegurándose de que no sea menor al mínimo ni mayor al máximo permitido
    setLeftPanelWidth(Math.max(minWidthPercent, Math.min(100 - minWidthPercent, widthPercent)));
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setSelectedUserId(user.id);
  };
  
    const handleInactivity = useCallback(() => {
    // console.log('Usuario inactivo, intervalo activado');
    setIsInactive(true);
  }, []);

  const resetInactivityTimer = useCallback(() => {
    // console.log('Actividad del usuario detectada, reiniciando temporizador');
    setIsInactive(false);
    clearTimeout(window.inactivityTimer);
    window.inactivityTimer = setTimeout(handleInactivity, 60 * 1000); // ajusta aquí para probar
  }, [handleInactivity]);

  useEffect(() => {
    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keypress', resetInactivityTimer);

    resetInactivityTimer(); // Iniciar el temporizador de inactividad

    return () => {
      clearTimeout(window.inactivityTimer);
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keypress', resetInactivityTimer);
    };
  }, [resetInactivityTimer]);
   

    useEffect(() => {
    let interval;
    if (isInactive) {
      // console.log('Intervalo de recarga de usuarios activado');
      interval = setInterval(() => {
        // console.log('Cargando usuarios...');
        loadScormUsers();
      }, 60000); // Carga los datos cada minuto si está inactivo
    } else {
      // console.log('Intervalo de recarga de usuarios desactivado');
    }

    return () => {
      if (interval) {
        // console.log('Limpieza del intervalo');
        clearInterval(interval);
      }
    };
  }, [isInactive, loadScormUsers]);
    
    const questions = [
      'Catridge de Lubricación',
      'Piola de Seguridad',
      'Polín Defectuoso'
    ];
    
    
    const logroCatridgeBienURL    = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Correcto-catridge-de-lubricacion.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvQ29ycmVjdG8tY2F0cmlkZ2UtZGUtbHVicmljYWNpb24uanBnIiwiaWF0IjoxNzE1NTcwNTQ3LCJleHAiOjE3NDcxMDY1NDd9.1rDymzOOchPCVS7-sXGMypGCswt2yvRTCzbv_MA13ZI&t=2024-05-13T03%3A22%3A27.827Z';
    const logroCatridgeMalURL     = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Incorrecto-catridge-de-lubricacion.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvSW5jb3JyZWN0by1jYXRyaWRnZS1kZS1sdWJyaWNhY2lvbi5qcGciLCJpYXQiOjE3MTU1NzA1NjIsImV4cCI6MTc0NzEwNjU2Mn0.ZnC7MpphlK9-Ayz7t_K3Tm4v_IGBcJQzOIPmd7RgHWA&t=2024-05-13T03%3A22%3A42.688Z';
    const logroProteccionBienURL  = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Correcto-barrera-de-seguridad.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvQ29ycmVjdG8tYmFycmVyYS1kZS1zZWd1cmlkYWQuanBnIiwiaWF0IjoxNzE1NTcwNTc3LCJleHAiOjE3NDcxMDY1Nzd9.jp9VwzVNYaB7Z3IjcNc995uHOACtRdOyrasMDQngXaE&t=2024-05-13T03%3A22%3A57.676Z';
    const logroProteccionMalURL   = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Incorrecto-barrera-de-proteccion.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvSW5jb3JyZWN0by1iYXJyZXJhLWRlLXByb3RlY2Npb24uanBnIiwiaWF0IjoxNzE1NTcwNjI3LCJleHAiOjE3NDcxMDY2Mjd9.D6pMSpzmIZeMw-mZgzDUHhE0CX4rAs2QQ-RAXt1eDTM&t=2024-05-13T03%3A23%3A47.258Z';
    const logroPolinBienURL       = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Correcto-polin-defectuoso.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvQ29ycmVjdG8tcG9saW4tZGVmZWN0dW9zby5qcGciLCJpYXQiOjE3MTU2MDM5MjcsImV4cCI6MTc0NzEzOTkyN30.q1jw337ryyMrehbnlqWEthpLmZk5_nG2zwjUopq80b4&t=2024-05-13T12%3A38%3A47.929Z';
    const logroPolinMalURL        = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Incorrecto-Polin-defectuoso.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvSW5jb3JyZWN0by1Qb2xpbi1kZWZlY3R1b3NvLmpwZyIsImlhdCI6MTcxNTU3MDY3NiwiZXhwIjoxNzQ3MTA2Njc2fQ.jbi4IYsvNzxrCEpVLh_D7opUBb49WBYeTHD8EuMwZAU&t=2024-05-13T03%3A24%3A36.516Z';
    


    const questionScreenshots = [
      { number: 1, correct: logroCatridgeBienURL, incorrect: logroCatridgeMalURL },
      { number: 2, correct: logroProteccionBienURL, incorrect: logroProteccionMalURL },
      { number: 3, correct: logroPolinBienURL, incorrect: logroPolinMalURL }
    ];


    const [searchQuery, setSearchQuery] = useState('');

    const formatDate = (dateString) => {
      const datePart = dateString.split('T')[0];
      const dateParts = datePart.split('-');
      return `${dateParts[2]}.${dateParts[1]}.${dateParts[0].slice(-2)}`;
    };

    // ... existing useEffect and functions

    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredScormUsers = searchQuery
    ? scormUsers.filter(user =>
        (user.email && user.email.toLowerCase().includes(searchQuery)) ||
        (user.curso && user.curso.toLowerCase().includes(searchQuery)) ||
        (user.puntaje && user.puntaje.toString().toLowerCase().includes(searchQuery))
      )
    : scormUsers;

  const pdfRef = useRef();

  const handleDownloadPDF = async () => {
    console.log('Generando PDF...');
    const element = document.createElement('div');
    const root = createRoot(element);
    
    // Asegúrate de que selectedUser tenga datos antes de pasar
    if (!selectedUser) {
      console.error('No hay usuario seleccionado');
      return; // Salir si no hay usuario seleccionado
    }

    // Renderiza el componente y pasa selectedUser y el nombre de la tabla
    root.render(
      <div style={{ backgroundColor: 'white', color: 'black', padding: '20px' }}>
        <VistaCertificado selectedUser={selectedUser} />
      </div>
    );

    console.log(element.innerHTML); // Verifica el contenido

    setTimeout(async () => {
      try {
        await html2pdf()
          .from(element)
          .save('RC09-Certificado.pdf');
      } catch (err) {
        console.error('Error al generar el PDF:', err);
      }
    }, 100);
  };

  const [moduloTitulo, setModuloTitulo] = useState('');

  useEffect(() => {
    const fetchModuloTitulo = async () => {
      if (!idcurso || !url) return;

      try {
        const { data, error } = await supabaseClient
          .from('modulos')
          .select('titulo')
          .eq('url', url)
          .eq('idcurso', idcurso)
          .single();

        if (error) throw error;
        setModuloTitulo(data.titulo || '');
      } catch (error) {
        console.error('Error al obtener el título del módulo:', error);
      }
    };

    fetchModuloTitulo();
  }, [idcurso, url, supabaseClient]);

// console.log(selectedUser);

return (
  <Box 
    sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      minHeight: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}
  >
    <Box className="split-screen" sx={{ flex: 1, display: 'flex' }}>
      <Paper 
        sx={{ 
          width: `${leftPanelWidth}%`,
          bgcolor: 'background.paper',
          borderRadius: 0,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <ReplyIcon 
              onClick={() => navigate(-1)}
              sx={{ 
                cursor: 'pointer',
                mr: 2,
                color: 'text.primary'
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <Typography 
                component="h1"
                variant="h5"
                sx={{ 
                  color: 'text.primary',
                  flexGrow: 1,
                  ml: 2,
                  fontWeight: 'medium',
                  '&.MuiTypography-root': {
                    color: 'text.primary'
                  }
                }}
              >
                {moduloTitulo}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Usuarios registrados
            </Typography>
            <Box sx={{ position: 'relative', mb: 2 }}>
              <SearchIcon sx={{ 
                position: 'absolute', 
                left: 8, 
                top: '50%', 
                transform: 'translateY(-50%)',
                color: 'text.secondary'
              }} />
              <InputBase
                placeholder="Buscar..."
                onChange={handleSearchChange}
                sx={{
                  width: '100%',
                  pl: 5,
                  pr: 2,
                  py: 1,
                  bgcolor: 'action.hover',
                  borderRadius: 1,
                  '& input': {
                    color: 'text.primary'
                  }
                }}
              />
            </Box>
          </Box>

          <TableContainer sx={{ flex: 1, px: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Puntaje</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Hora</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredScormUsers.map((user, index) => (
                  <TableRow 
                    key={index}
                    onClick={() => handleUserClick(user)}
                    selected={selectedUserId === user.id}
                    hover
                    sx={{
                      cursor: 'pointer',
                      '&.Mui-selected': {
                        bgcolor: 'action.selected'
                      }
                    }}
                  >
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.puntaje}</TableCell>
                    <TableCell>{formatDate(user.inicio)}</TableCell>
                    <TableCell>{user.termino.substring(11, 16)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>

      <Divider onDrag={handleDrag} />

      <Box 
        sx={{ 
          width: `${100 - leftPanelWidth}%`,
          bgcolor: 'background.default',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {selectedUser && selectedUser.iduser && selectedUser.intento ? (
          <VistaResultado 
            userId={selectedUser.iduser} 
            intento={selectedUser.intento}
            showNavBar={false}
            isFromDashboard={true}
          />
        ) : (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            p: 3
          }}>
            <Box>
              <Typography variant="h4" sx={{ mb: 2 }}>
                Bienvenido
              </Typography>
              <Typography variant="h5">
                Selecciona un usuario para ver sus resultados
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  </Box>
);
};

export default VistaModulo;