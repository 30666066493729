import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useTheme } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import html2pdf from 'html2pdf.js';
import VistaCertificado from './VistaCertificado';
import './VistaResultado.css';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Modal from 'react-modal';
import { Box, Paper, Typography, Grid, Button, IconButton, Card, CardContent, CardMedia, Chip } from '@mui/material';
import { Close as CloseIcon, Download as DownloadIcon, Reply as ReplyIcon } from '@mui/icons-material';

Modal.setAppElement('#root');

const VistaResultado = ({ userId: propUserId, intento: propIntento, showNavBar = true, isFromDashboard = false }) => {
  const theme = useTheme();
  const { userId: paramUserId, intento: paramIntento } = useParams();
  const location = useLocation();
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const supabaseClient = useSupabaseClient();
  const navigate = useNavigate();
  const [entendimientoUrls, setEntendimientoUrls] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionScreenshots, setQuestionScreenshots] = useState([]);
  const [cursoData, setCursoData] = useState(null);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Usa los props si están disponibles, de lo contrario usa los parámetros de la URL
  const userId = propUserId || paramUserId;
  const intento = propIntento || paramIntento;
  const showNavBarFromState = location.state?.showNavBar ?? showNavBar;

  // Calcular puntaje y puntajeFinal solo si selectedUser no es null
  const puntajeFinal = selectedUser ? Math.floor(parseFloat(selectedUser.porcentaje.replace(',', '.'))) === 99 ? 100 : Math.floor(parseFloat(selectedUser.porcentaje.replace(',', '.'))) : 0; // Si no hay usuario, puntajeFinal es 0

  const handleDownloadPDF = async () => {
    console.log('Generando PDF...');
    const element = document.createElement('div');
    const root = createRoot(element);
    
    // Asegúrate de que selectedUser tenga datos antes de pasar
    if (!selectedUser) {
      console.error('No hay usuario seleccionado');
      return; // Salir si no hay usuario seleccionado
    }

    // Renderiza el componente y pasa selectedUser y el nombre de la tabla
    root.render(
      <div style={{ backgroundColor: 'white', color: 'black', padding: '20px' }}>
        <VistaCertificado selectedUser={selectedUser} cursoData={cursoData} questions={questions} />
      </div>
    );

    console.log(element.innerHTML); // Verifica el contenido

    setTimeout(async () => {
      try {
        await html2pdf()
          .from(element)
          .save(`Certificado-${selectedUser.idcurso}-${selectedUser.modulo}-${selectedUser.email}.pdf`); // Incluye idcurso, modulo y email en el nombre del archivo
      } catch (err) {
        console.error('Error al generar el PDF:', err);
      }
    }, 100);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      if (!userId || !intento) {
        console.error('userId o intento no proporcionados');
        setIsLoading(false);
        return;
      }

      try {
        const { data, error } = await supabaseClient
          .from('graduados')
          .select('*')
          .eq('iduser', userId)
          .eq('intento', intento)
          .single();

        if (error) throw error;
        setSelectedUser(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [userId, intento, supabaseClient]);

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        if (!selectedUser?.idcurso || !selectedUser?.modulo) {
          console.error('idcurso o modulo no están disponibles');
          return;
        }
        const { data, error } = await supabaseClient
          .from('modulos')
          .select('*')
          .eq('idcurso', selectedUser.idcurso)
          .eq('modulo', selectedUser.modulo)
          .single();

        if (error) {
          console.error('Error al obtener datos del módulo:', error);
          return;
        }

        const questionsData = [
          { pregunta: 'Pregunta 1:', text: data.pregunta1, approved: selectedUser.resultadopregunta1 === 'correcta' },
          { pregunta: 'Pregunta 2:', text: data.pregunta2, approved: selectedUser.resultadopregunta2 === 'correcta' },
          { pregunta: 'Pregunta 3:', text: data.pregunta3, approved: selectedUser.resultadopregunta3 === 'correcta' },
          { pregunta: 'Pregunta 4:', text: data.pregunta4, approved: selectedUser.resultadopregunta4 === 'correcta' },
          { pregunta: 'Pregunta 5:', text: data.pregunta5, approved: selectedUser.resultadopregunta5 === 'correcta' },
          { pregunta: 'Pregunta 6:', text: data.pregunta6, approved: selectedUser.resultadopregunta6 === 'correcta' },
          { pregunta: 'Pregunta 7:', text: data.pregunta7, approved: selectedUser.resultadopregunta7 === 'correcta' }
        ].filter(question => question.text !== null && question.text.trim() !== '');

        const questionScreenshotsData = [
          { number: 1, correct: data.url_pregunta1_buena, incorrect: data.url_pregunta1_mala },
          { number: 2, correct: data.url_pregunta2_buena, incorrect: data.url_pregunta2_mala },
          { number: 3, correct: data.url_pregunta3_buena, incorrect: data.url_pregunta3_mala },
          { number: 4, correct: data.url_pregunta4_buena, incorrect: data.url_pregunta4_mala },
          { number: 5, correct: data.url_pregunta5_buena, incorrect: data.url_pregunta5_mala },
          { number: 6, correct: data.url_pregunta6_buena, incorrect: data.url_pregunta6_mala },
          { number: 7, correct: data.url_pregunta7_buena, incorrect: data.url_pregunta7_mala }
        ];

        // Agregar URLs de entendimiento si no son null
        const entendimientoUrlsData = [
          data.url_entendimiento1,
          data.url_entendimiento2,
          data.url_entendimiento3,
          data.url_entendimiento4,
          data.url_entendimiento5,
          data.url_entendimiento6
        ].filter(url => url !== null);

        setQuestions(questionsData);
        setQuestionScreenshots(questionScreenshotsData);
        setEntendimientoUrls(entendimientoUrlsData); // Establecer las URLs de entendimiento

      } catch (error) {
        console.error('Error fetching module data:', error);
      }
    };

    if (selectedUser) {
      fetchModuleData();
    }
  }, [selectedUser, supabaseClient]);

  useEffect(() => {
    const fetchCursoData = async () => {
      if (!selectedUser?.idcurso) {
        console.error('idcurso no está disponible');
        return;
      }

      try {
        const { data, error } = await supabaseClient
          .from('cursos')
          .select('curso, url_portada')
          .eq('idcurso', selectedUser.idcurso)
          .single();

        if (error) {
          console.error('Error al obtener datos del curso:', error);
          return;
        }

        setCursoData(data);
      } catch (error) {
        console.error('Error fetching curso data:', error);
      }
    };

    if (selectedUser) {
      fetchCursoData();
    }
  }, [selectedUser, supabaseClient]);

  const openGallery = (images, index) => {
    const filteredImages = images.filter(image => image.original !== null);
    console.log('Opening gallery with images:', filteredImages);
    console.log('Starting index:', index);
    setGalleryImages(filteredImages);
    setCurrentImageIndex(index);
    setIsGalleryOpen(true);
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };

  const handleSlide = (index) => {
    console.log('Current slide index:', index);
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    if (selectedUser) {
      console.log('Estado de las respuestas del usuario:', {
        pregunta1: selectedUser.resultadopregunta1,
        pregunta2: selectedUser.resultadopregunta2,
        pregunta3: selectedUser.resultadopregunta3,
        pregunta4: selectedUser.resultadopregunta4,
        pregunta5: selectedUser.resultadopregunta5,
        pregunta6: selectedUser.resultadopregunta6,
        pregunta7: selectedUser.resultadopregunta7,
      });
    }
  }, [selectedUser]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (!selectedUser) {
    return <div>No se encontraron datos para este usuario e intento.</div>;
  }

  return (
    <Box 
      className="no-select"
      sx={{ 
        minHeight: '100vh',
        bgcolor: 'background.default',
        color: 'text.primary',
        display: 'flex',
        justifyContent: showNavBarFromState ? 'center' : 'flex-start',
        p: 2
      }}
      tabIndex="-1"
    >
      <Modal
        isOpen={isGalleryOpen}
        onRequestClose={closeGallery}
        contentLabel="Galería de Imágenes"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1000
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '90%',
            maxHeight: '90%',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            borderRadius: '8px',
            padding: '20px'
          }
        }}
      >
        <ImageGallery
          items={galleryImages}
          startIndex={currentImageIndex}
          onSlide={handleSlide}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          onErrorImageURL="default-image-url"
        />
        <IconButton
          onClick={closeGallery}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'text.primary'
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal>

      <Box 
        sx={{ 
          width: showNavBarFromState ? '900px' : '100%',
          maxWidth: '100%'
        }}
      >
        <Paper 
          elevation={3}
          sx={{ 
            p: 3,
            mb: 4,
            bgcolor: 'background.paper',
            borderRadius: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <Box 
              component="img"
              src={`${cursoData?.url_portada || 'default-image-url'}?v=${new Date().getTime()}`}
              alt="Portada del curso"
              sx={{ width: 80, height: 80, mr: 2, objectFit: 'contain' }}
            />
            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
              {cursoData?.curso || 'Título del Curso'}
            </Typography>
          </Box>

          {!isFromDashboard && (
            <IconButton 
              onClick={() => navigate(-1)}
              sx={{ mb: 2 }}
            >
              <ReplyIcon />
            </IconButton>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 4 }}>
            <Box>
              <Typography 
                component="a" 
                href={`/usuario-perfil/${selectedUser?.iduser}`}
                sx={{ 
                  color: 'primary.main',
                  textDecoration: 'underline',
                  display: 'block',
                  mb: 1
                }}
              >
                {selectedUser?.email?.toLowerCase() || ''}
              </Typography>
              <Typography>Curso: {selectedUser?.idcurso || ''}</Typography>
              <Typography>Módulo: {selectedUser?.modulo || ''}</Typography>
              <Typography>Puntaje: {selectedUser?.puntaje || ''}</Typography>
              <Typography>Código: {selectedUser?.intento || ''}</Typography>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <Chip
                label={puntajeFinal >= 99 ? 'Aprobado' : 'Reprobado'}
                color={puntajeFinal >= 99 ? 'success' : 'error'}
                sx={{ mb: 1 }}
              />
              <Typography variant="h4" sx={{ mb: 2 }}>
                {puntajeFinal}%
              </Typography>
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadPDF}
                sx={{ 
                  bgcolor: 'primary.main',
                  '&:hover': {
                    bgcolor: 'primary.dark'
                  }
                }}
              >
                Descargar Informe
              </Button>
            </Box>
          </Box>
        </Paper>

        <Paper 
          elevation={3}
          sx={{ 
            p: 3,
            mb: 4,
            bgcolor: 'background.paper',
            borderRadius: 2
          }}
        >
          <Typography variant="h5" component="h2" align="center" sx={{ mb: 4 }}>
            DECLARACIONES DE ENTENDIMIENTO
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            {entendimientoUrls.map((url, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card 
                  sx={{ 
                    height: '100%',
                    cursor: 'pointer',
                    transition: '0.3s',
                    '&:hover': {
                      transform: 'scale(1.02)'
                    }
                  }}
                  onClick={() => openGallery(entendimientoUrls.map(url => ({ original: url })), index)}
                >
                  <CardMedia
                    component="img"
                    image={url}
                    alt={`Declaración entendimiento ${index + 1}`}
                    sx={{ height: 200, objectFit: 'contain' }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>

        <Paper 
          elevation={3}
          sx={{ 
            p: 3,
            bgcolor: 'background.paper',
            borderRadius: 2
          }}
        >
          <Typography variant="h5" component="h2" align="center" sx={{ mb: 4 }}>
            PREGUNTAS
          </Typography>
          <Grid container spacing={3}>
            {questions.map((question, index) => {
              const questionNumber = index + 1;
              const questionKey = `resultadopregunta${questionNumber}`;
              const isCorrect = selectedUser[questionKey] === 'correcta';
              const screenshot = questionScreenshots.find(s => s.number === questionNumber);
              const screenshotFilename = isCorrect ? screenshot.correct : screenshot.incorrect;

              return (
                <Grid item xs={12} sm={6} md={4} key={questionNumber}>
                  <Card 
                    sx={{ 
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      bgcolor: 'background.paper'
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={screenshotFilename}
                      alt={`Respuesta ${isCorrect ? 'Correcta' : 'Incorrecta'}`}
                      sx={{ 
                        height: 200,
                        cursor: 'pointer',
                        objectFit: 'cover'
                      }}
                      onClick={() => openGallery(
                        questionScreenshots.map(s => ({
                          original: selectedUser[`resultadopregunta${s.number}`] === 'correcta' ? s.correct : s.incorrect
                        })), 
                        index
                      )}
                    />
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Pregunta {questionNumber}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
                        {question.text.toUpperCase()}
                      </Typography>
                      <Chip
                        label={isCorrect ? 'Correcta' : 'Incorrecta'}
                        color={isCorrect ? 'success' : 'error'}
                        sx={{ width: '100%' }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default VistaResultado;