import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import { 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Box, 
  ListItemButton,
  Avatar,
  Divider
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SupportIcon from '@mui/icons-material/Support';
import SchoolIcon from '@mui/icons-material/School';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useUserContext } from './UserContext';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  const { isDarkMode, toggleTheme } = useTheme();
  const { usuario } = useUserContext();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const fileInputRef = useRef(null);
  const supabase = useSupabaseClient();

  useEffect(() => {
    const cargarAvatar = async () => {
      if (!usuario?.email) return;

      try {
        const { data, error } = await supabase
          .from('usuarios')
          .select('avatar_url')
          .eq('email', usuario.email)
          .single();

        if (error) throw error;
        if (data?.avatar_url) {
          setAvatarUrl(data.avatar_url);
        }
      } catch (error) {
        console.error('Error al cargar el avatar:', error);
      }
    };

    cargarAvatar();
  }, [usuario?.email, supabase]);

  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files?.[0];
    if (!file || !usuario?.email) return;

    try {
      // Validar tipo de archivo
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
      if (!allowedTypes.includes(file.type)) {
        alert('Por favor, sube solo imágenes en formato JPG, PNG o GIF.');
        return;
      }

      // Validar tamaño (máximo 2MB)
      const maxSize = 2 * 1024 * 1024; // 2MB
      if (file.size > maxSize) {
        alert('La imagen no debe superar los 2MB.');
        return;
      }

      // Sanitizar nombre de archivo
      const timestamp = new Date().toISOString().replace(/[^0-9]/g, '').slice(0, 14);
      const fileExt = file.type.split('/')[1];
      const sanitizedName = file.name
        .split('.')[0]
        .replace(/[^a-zA-Z0-9]/g, '-')
        .toLowerCase();
      const fileName = `${sanitizedName}-${timestamp}.${fileExt}`;
      const filePath = `private/avatares/${usuario.email}/${fileName}`;

      // Borrar avatar anterior si existe
      if (avatarUrl) {
        try {
          console.log('URL del avatar actual:', avatarUrl);
          const oldPath = decodeURIComponent(avatarUrl.split('codelco/')[1].split('?')[0]);
          console.log('Ruta del archivo a eliminar:', oldPath);
          
          const { error: deleteError } = await supabase.storage
            .from('codelco')
            .remove([oldPath]);
          
          if (deleteError) {
            console.error('Error al eliminar el archivo:', deleteError);
          } else {
            console.log('Archivo eliminado exitosamente');
          }
        } catch (error) {
          console.error('Error al eliminar el avatar anterior:', error);
        }
      }

      // Subir nuevo avatar
      const { error: uploadError } = await supabase.storage
        .from('codelco')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) throw uploadError;

      // Obtener URL del nuevo avatar
      const { data } = await supabase.storage
        .from('codelco')
        .createSignedUrl(filePath, 31536000); // URL firmada válida por 1 año

      if (!data?.signedUrl) throw new Error('No se pudo obtener la URL firmada');

      // Actualizar el avatar en la base de datos del usuario
      const { error: updateError } = await supabase
        .from('usuarios')
        .update({ avatar_url: data.signedUrl })
        .eq('email', usuario.email);

      if (updateError) throw updateError;

      setAvatarUrl(data.signedUrl);

    } catch (error) {
      console.error('Error al subir el avatar:', error);
      alert('Error al subir la imagen. Por favor, intenta de nuevo.');
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const getInitials = (nombre, apellido) => {
    const firstInitial = nombre ? nombre.charAt(0).toUpperCase() : '';
    const lastInitial = apellido ? apellido.charAt(0).toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
  };

  const menuItems = [
    { text: 'Cursos', icon: <SchoolIcon />, path: '/cursos' },
    { text: 'Usuarios', icon: <PersonIcon />, path: '/usuarios' },
    { text: 'Soporte', icon: <SupportIcon />, path: '/soporte' }
  ];

  return (
    <>
      <div 
        className="header-content" 
        style={{ 
          backgroundColor: isDarkMode ? '#1e1e1e' : '#e55302',
          userSelect: 'none' 
        }}
      >
        <div className="logo-container" style={{ cursor: 'pointer' }} onClick={() => navigate('/cursos')}>
          <img 
            className="logo-codelco-dashboard" 
            src={isDarkMode ? "/img/logo-codelco-naranjo.png" : "/img/logo-codelco.png"} 
            alt="Logo Codelco" 
          />
        </div>
        <div className="header-text text-center m-20" style={{ cursor: 'default' }}>
          <h2 style={{ color: '#ffffff' }}>
            Inducción 360° / VR / Multiplayer
          </h2>
          <p style={{ color: 'rgba(255,255,255,0.7)' }}>
            Riesgos críticos
          </p>
        </div>
        <div className="logo-container mt-10">
          <div className="user-info d-flex align-items-center justify-content-between" style={{ cursor: 'default' }}>
            <div className="user-details">
              <p 
                className="user-name" 
                style={{ 
                  margin: 0,
                  color: '#ffffff',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  navigate('/mi-perfil');
                  setDrawerOpen(false);
                }}
              >
                {usuario ? `${usuario.nombre} ${usuario.apellido}` : 'Usuario no autenticado'}
              </p>
              <p className="user-email" style={{ color: 'rgba(255,255,255,0.7)' }}>
                {usuario ? usuario.email : ''}
              </p>
            </div>
            <span 
              className="material-symbols-outlined ml-5 cursor-pointer mr-8" 
              onClick={toggleDrawer(true)}
              style={{ color: '#ffffff' }}
            >
              menu
            </span>
          </div>
        </div>
      </div>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff'
          }
        }}
      >
        <Box sx={{ width: 320, display: 'flex', flexDirection: 'column', height: '100%' }}>
          {/* Sección de usuario */}
          <Box sx={{ p: 3, textAlign: 'center' }}>
            {usuario && (
              <>
                <Box
                  sx={{
                    position: 'relative',
                    width: 60,
                    height: 60,
                    margin: '0 auto 16px',
                    cursor: 'pointer',
                    '&:hover::after': {
                      content: '"edit"',
                      fontFamily: '"Material Symbols Outlined"',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      color: '#ffffff',
                      borderRadius: '50%',
                      opacity: 0,
                      transition: 'opacity 0.3s ease',
                      animation: 'fadeIn 0.3s ease forwards'
                    },
                    '@keyframes fadeIn': {
                      from: {
                        opacity: 0
                      },
                      to: {
                        opacity: 1
                      }
                    }
                  }}
                  onClick={handleAvatarClick}
                >
                  <Avatar 
                    sx={{ 
                      width: 60, 
                      height: 60,
                      bgcolor: '#e55302',
                      color: '#ffffff',
                      fontSize: '1.2rem'
                    }}
                    src={avatarUrl}
                    imgProps={{
                      onError: () => setAvatarUrl(null),
                      style: { objectFit: 'cover' }
                    }}
                  >
                    {!avatarUrl && getInitials(usuario.nombre, usuario.apellido)}
                  </Avatar>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </Box>
                <Box sx={{ 
                  color: isDarkMode ? '#ffffff' : '#000000',
                  '& > *': { margin: 0 }
                }}>
                  <p 
                    style={{ 
                      fontWeight: 'bold', 
                      fontSize: '1.1rem', 
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      navigate('/mi-perfil');
                      setDrawerOpen(false);
                    }}
                  >
                    {`${usuario.nombre} ${usuario.apellido}`}
                  </p>
                  <p style={{ color: isDarkMode ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)', fontSize: '0.9rem' }}>
                    {usuario.empresa || 'Sin empresa'}
                  </p>
                </Box>
              </>
            )}
          </Box>

          <Divider sx={{ bgcolor: isDarkMode ? 'rgba(255,255,255,0.12)' : 'rgba(0,0,0,0.12)' }} />

          {/* Menú principal */}
          <List sx={{ flexGrow: 1 }}>
            {menuItems.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton 
                  onClick={() => {
                    navigate(item.path);
                    setDrawerOpen(false);
                  }}
                  sx={{
                    color: isDarkMode ? '#ffffff' : '#000000',
                    '& .MuiListItemIcon-root': {
                      color: '#e55302'
                    }
                  }}
                >
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Divider sx={{ bgcolor: isDarkMode ? 'rgba(255,255,255,0.12)' : 'rgba(0,0,0,0.12)' }} />

          {/* Sección inferior */}
          <List>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  toggleTheme();
                  setDrawerOpen(false);
                }}
                sx={{
                  borderRadius: 1,
                  '&:hover': {
                    bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <ListItemIcon>
                  {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </ListItemIcon>
                <ListItemText primary={isDarkMode ? 'Modo Claro' : 'Modo Oscuro'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton 
                onClick={() => {
                  navigate('/salir');
                  setDrawerOpen(false);
                }}
                sx={{
                  color: isDarkMode ? '#ffffff' : '#000000',
                  '& .MuiListItemIcon-root': {
                    color: '#e55302'
                  }
                }}
              >
                <ListItemText primary="Cerrar Sesión" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;