import React, { createContext, useContext, useState, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme, CssBaseline } from '@mui/material';

const ThemeContext = createContext();

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme debe ser usado dentro de un ThemeProvider');
  }
  return context;
};

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });

  const toggleTheme = () => {
    setIsDarkMode(prev => !prev);
  };

  useEffect(() => {
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#121212',
        paper: 'transparent'
      },
      primary: {
        main: '#e55302',
        light: '#ff7a33',
        dark: '#b23d00',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#ffffff',
        contrastText: '#000000',
      },
      text: {
        primary: '#ffffff',
        secondary: 'rgba(255, 255, 255, 0.7)',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#121212',
            color: '#ffffff'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 8,
          },
          contained: {
            backgroundColor: '#e55302',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#b23d00',
            },
          },
          outlined: {
            borderColor: '#e55302',
            color: '#e55302',
            '&:hover': {
              borderColor: '#b23d00',
              backgroundColor: 'rgba(229, 83, 2, 0.04)',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#ffffff',
          },
          h1: {
            color: '#ffffff',
          },
          h2: {
            color: '#ffffff',
          },
          h3: {
            color: '#ffffff',
          },
          h4: {
            color: '#ffffff',
          },
          h5: {
            color: '#ffffff',
          },
          h6: {
            color: '#ffffff',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent'
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent'
          }
        }
      }
    }
  });

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      background: {
        default: '#ffffff',
        paper: 'transparent'
      },
      primary: {
        main: '#e55302',
        light: '#ff7a33',
        dark: '#b23d00',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#2E2E2E',
        contrastText: '#ffffff',
      },
      text: {
        primary: '#000000',
        secondary: 'rgba(0, 0, 0, 0.6)',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#ffffff',
            color: '#000000'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 8,
          },
          contained: {
            backgroundColor: '#e55302',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#b23d00',
            },
          },
          outlined: {
            borderColor: '#e55302',
            color: '#e55302',
            '&:hover': {
              borderColor: '#b23d00',
              backgroundColor: 'rgba(229, 83, 2, 0.04)',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#000000',
          },
          h1: {
            color: '#000000',
          },
          h2: {
            color: '#000000',
          },
          h3: {
            color: '#000000',
          },
          h4: {
            color: '#000000',
          },
          h5: {
            color: '#000000',
          },
          h6: {
            color: '#000000',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent'
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent'
          }
        }
      }
    }
  });

  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
