import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  useTheme as useMuiTheme
} from '@mui/material';
import { useTheme } from '../context/ThemeContext';

const ticketsClient = createClient(
  import.meta.env.VITE_TICKETS_SUPABASE_URL,
  import.meta.env.VITE_TICKETS_SUPABASE_ANON_KEY
);

const Soporte = () => {
  const { isDarkMode } = useTheme();
  const muiTheme = useMuiTheme();
  const [tickets, setTickets] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [nuevoTicket, setNuevoTicket] = useState({
    descripcion: '',
    prioridad: 'normal'
  });

  const cargarTickets = async () => {
    const { data, error } = await ticketsClient
      .from('tickets')
      .select('*')
      .eq('cliente', 1)
      .eq('plataforma', 1)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error al cargar tickets:', error);
      return;
    }

    setTickets(data || []);
  };

  useEffect(() => {
    cargarTickets();
  }, []);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setNuevoTicket({ descripcion: '', prioridad: 'normal' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNuevoTicket(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const crearTicket = async () => {
    const { error } = await ticketsClient
      .from('tickets')
      .insert([{
        cliente: 1,
        plataforma: 1,
        descripcion: nuevoTicket.descripcion,
        prioridad: nuevoTicket.prioridad,
        estado: 'abierto',
        fecha_apertura: new Date().toISOString()
      }]);

    if (error) {
      console.error('Error al crear ticket:', error);
      return;
    }

    handleCloseModal();
    cargarTickets();
  };

  const getPrioridadColor = (prioridad) => {
    switch (prioridad) {
      case 'alta':
        return 'error';
      case 'normal':
        return 'warning';
      case 'baja':
        return 'success';
      default:
        return 'default';
    }
  };

  const getEstadoColor = (estado) => {
    switch (estado) {
      case 'abierto':
        return 'primary';
      case 'en_proceso':
        return 'warning';
      case 'cerrado':
        return 'default';
      default:
        return 'default';
    }
  };

  return (
    <Box 
      className="page-container"
      sx={{ 
        bgcolor: isDarkMode ? 'background.default' : '#ffffff'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" sx={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
          Soporte Técnico
        </Typography>
        <Button
          variant="contained"
          onClick={handleOpenModal}
          sx={{
            backgroundColor: '#e55302',
            '&:hover': {
              backgroundColor: '#b23d00'
            }
          }}
        >
          Nuevo Ticket
        </Button>
      </Box>

      <Box sx={{ 
        width: '100%',
        backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff',
        borderRadius: 1,
        overflow: 'hidden'
      }}>
        <Table sx={{
          backgroundColor: 'transparent',
          '& .MuiTableCell-root': {
            backgroundColor: 'transparent',
            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'
          }
        }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ 
                backgroundColor: isDarkMode ? '#2d2d2d !important' : '#f5f5f5 !important',
                color: isDarkMode ? '#ffffff' : '#000000'
              }}>ID</TableCell>
              <TableCell sx={{ 
                backgroundColor: isDarkMode ? '#2d2d2d !important' : '#f5f5f5 !important',
                color: isDarkMode ? '#ffffff' : '#000000'
              }}>Fecha</TableCell>
              <TableCell sx={{ 
                backgroundColor: isDarkMode ? '#2d2d2d !important' : '#f5f5f5 !important',
                color: isDarkMode ? '#ffffff' : '#000000'
              }}>Descripción</TableCell>
              <TableCell sx={{ 
                backgroundColor: isDarkMode ? '#2d2d2d !important' : '#f5f5f5 !important',
                color: isDarkMode ? '#ffffff' : '#000000'
              }}>Prioridad</TableCell>
              <TableCell sx={{ 
                backgroundColor: isDarkMode ? '#2d2d2d !important' : '#f5f5f5 !important',
                color: isDarkMode ? '#ffffff' : '#000000'
              }}>Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.map((ticket) => (
              <TableRow
                key={ticket.id}
                sx={{
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'
                  }
                }}
              >
                <TableCell sx={{ color: isDarkMode ? '#ffffff' : '#000000' }}>{ticket.id}</TableCell>
                <TableCell sx={{ color: isDarkMode ? '#ffffff' : '#000000' }}>{new Date(ticket.created_at).toLocaleDateString('es-CL')}</TableCell>
                <TableCell sx={{ color: isDarkMode ? '#ffffff' : '#000000' }}>{ticket.descripcion}</TableCell>
                <TableCell>
                  <Chip 
                    label={ticket.prioridad} 
                    color={getPrioridadColor(ticket.prioridad)} 
                    size="small"
                    sx={{ 
                      backgroundColor: getPrioridadColor(ticket.prioridad) === 'error' ? '#f44336' : getPrioridadColor(ticket.prioridad) === 'warning' ? '#ff9800' : '#4caf50',
                      color: '#ffffff'
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Chip 
                    label={ticket.estado}
                    color={getEstadoColor(ticket.estado)}
                    size="small"
                    sx={{
                      backgroundColor: getEstadoColor(ticket.estado) === 'primary' ? '#2196f3' : getEstadoColor(ticket.estado) === 'warning' ? '#ff9800' : '#757575',
                      color: '#ffffff'
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      <Dialog 
        open={openModal} 
        onClose={handleCloseModal} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: isDarkMode ? 'background.paper' : '#ffffff',
            color: isDarkMode ? '#ffffff' : '#000000'
          }
        }}
      >
        <DialogTitle>Crear Nuevo Ticket</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <TextField
              autoFocus
              name="descripcion"
              label="Descripción del problema"
              multiline
              rows={4}
              fullWidth
              value={nuevoTicket.descripcion}
              onChange={handleChange}
              sx={{ 
                mb: 2,
                '& .MuiInputLabel-root': {
                  color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : undefined
                },
                '& .MuiOutlinedInput-root': {
                  color: isDarkMode ? '#ffffff' : '#000000',
                  '& fieldset': {
                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : undefined
                  }
                }
              }}
            />
            <FormControl 
              fullWidth
              sx={{
                '& .MuiInputLabel-root': {
                  color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : undefined
                },
                '& .MuiOutlinedInput-root': {
                  color: isDarkMode ? '#ffffff' : '#000000',
                  '& fieldset': {
                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : undefined
                  }
                }
              }}
            >
              <InputLabel>Prioridad</InputLabel>
              <Select
                name="prioridad"
                value={nuevoTicket.prioridad}
                onChange={handleChange}
                label="Prioridad"
              >
                <MenuItem value="baja">Baja</MenuItem>
                <MenuItem value="normal">Normal</MenuItem>
                <MenuItem value="alta">Alta</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button
            onClick={crearTicket}
            variant="contained"
            sx={{
              backgroundColor: '#e55302',
              '&:hover': {
                backgroundColor: '#b23d00'
              }
            }}
          >
            Crear Ticket
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Soporte;
