import React, { useEffect } from 'react';
import { Box, Paper, Typography, TextField, Button, Divider } from '@mui/material';
import { useUserContext } from '../objetos/UserContext';
import { useTheme } from '../context/ThemeContext';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useState } from 'react';

const MiPerfil = () => {
    const { usuario, setUsuario } = useUserContext();
    const { isDarkMode } = useTheme();
    const supabase = useSupabaseClient();
    const [isEditing, setIsEditing] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');
    const [editingUser, setEditingUser] = useState({
        nombre: '',
        apellido: '',
        empresa: ''
    });
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        if (usuario) {
            setEditingUser({
                nombre: usuario.nombre || '',
                apellido: usuario.apellido || '',
                empresa: usuario.empresa || ''
            });
        }
    }, [usuario]);

    const handleEditUser = async () => {
        setIsEditing(true);
        try {
            if (editingUser.nombre.trim() === '') {
                setMessage('El nombre es obligatorio');
                setIsEditing(false);
                return;
            }
            if (editingUser.nombre.length < 3 || !/^[a-zA-ZÀ-ÿ\s]+$/u.test(editingUser.nombre)) {
                setMessage('El nombre debe tener más de 3 caracteres y solo contener texto');
                setIsEditing(false);
                return;
            }
            if (editingUser.apellido.trim() === '') {
                setMessage('El apellido es obligatorio');
                setIsEditing(false);
                return;
            }
            if (editingUser.apellido.length < 3 || !/^[a-zA-ZÀ-ÿ\s]+$/u.test(editingUser.apellido)) {
                setMessage('El apellido debe tener más de 3 caracteres y solo contener texto');
                setIsEditing(false);
                return;
            }

            const { data, error } = await supabase
                .from('usuarios')
                .update({
                    nombre: editingUser.nombre.charAt(0).toUpperCase() + editingUser.nombre.slice(1).toLowerCase(),
                    apellido: editingUser.apellido.charAt(0).toUpperCase() + editingUser.apellido.slice(1).toLowerCase(),
                    empresa: editingUser.empresa.charAt(0).toUpperCase() + editingUser.empresa.slice(1).toLowerCase()
                })
                .eq('email', usuario.email.toLowerCase());

            if (error) throw error;

            setMessage('Datos actualizados correctamente');
            setUsuario({
                ...usuario,
                ...editingUser
            });

            setTimeout(() => {
                setMessage('');
            }, 3000);

        } catch (error) {
            console.error('Error:', error);
            setMessage(`Error al actualizar los datos: ${error.message}`);
        } finally {
            setIsEditing(false);
        }
    };

    const handleChangePassword = async () => {
        setIsChangingPassword(true);
        try {
            if (newPassword.length < 6) {
                setPasswordMessage('La contraseña debe tener al menos 6 caracteres');
                setIsChangingPassword(false);
                return;
            }

            if (newPassword !== confirmPassword) {
                setPasswordMessage('Las contraseñas no coinciden');
                setIsChangingPassword(false);
                return;
            }

            // Actualizar contraseña en la tabla usuarios
            const { error: updateError } = await supabase
                .from('usuarios')
                .update({
                    password: newPassword
                })
                .eq('email', usuario.email.toLowerCase());

            if (updateError) throw updateError;

            // Actualizar contraseña en auth
            const { error: authError } = await supabase.auth.updateUser({
                password: newPassword
            });

            if (authError) throw authError;

            setPasswordMessage('Contraseña actualizada correctamente');
            setNewPassword('');
            setConfirmPassword('');

            setTimeout(() => {
                setPasswordMessage('');
            }, 3000);

        } catch (error) {
            console.error('Error:', error);
            setPasswordMessage(`Error al actualizar la contraseña: ${error.message}`);
        } finally {
            setIsChangingPassword(false);
        }
    };

    return (
        <Box 
            className="page-container"
            sx={{ 
                bgcolor: isDarkMode ? 'background.default' : '#ffffff',
                minHeight: 'calc(100vh - 64px - 50px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                py: 4
            }}
        >
            <Paper 
                elevation={3}
                sx={{
                    width: '100%',
                    maxWidth: 600,
                    p: 4,
                    bgcolor: isDarkMode ? '#1e1e1e' : '#ffffff'
                }}
            >
                <Typography variant="h5" component="h1" gutterBottom sx={{ color: '#e55302' }}>
                    Mi Perfil
                </Typography>

                <Box sx={{ mt: 3 }}>
                    <TextField
                        sx={{
                            mb: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                },
                                '&:hover fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: 'text.primary',
                            }
                        }}
                        fullWidth
                        label="Nombre"
                        value={editingUser.nombre}
                        onChange={(e) => setEditingUser({ ...editingUser, nombre: e.target.value })}
                    />
                    <TextField
                        sx={{
                            mb: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                },
                                '&:hover fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: 'text.primary',
                            }
                        }}
                        fullWidth
                        label="Apellido"
                        value={editingUser.apellido}
                        onChange={(e) => setEditingUser({ ...editingUser, apellido: e.target.value })}
                    />
                    <TextField
                        sx={{
                            mb: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                },
                                '&:hover fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: 'text.primary',
                            }
                        }}
                        fullWidth
                        label="Email"
                        value={usuario?.email || ''}
                        disabled
                    />
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                },
                                '&:hover fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: 'text.primary',
                            }
                        }}
                        fullWidth
                        label="Empresa"
                        value={editingUser.empresa}
                        onChange={(e) => setEditingUser({ ...editingUser, empresa: e.target.value })}
                    />
                </Box>

                {message && (
                    <Box sx={{ 
                        mt: 2, 
                        color: message.includes('Error') ? 'error.main' : 'success.main',
                        textAlign: 'center'
                    }}>
                        {message}
                    </Box>
                )}

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                        onClick={handleEditUser}
                        disabled={isEditing}
                        sx={{
                            bgcolor: '#e55302',
                            color: '#ffffff',
                            px: 4,
                            '&:hover': {
                                bgcolor: '#b23d00'
                            },
                            '&:disabled': {
                                bgcolor: 'rgba(229, 83, 2, 0.5)',
                                color: '#ffffff'
                            }
                        }}
                    >
                        {isEditing ? 'Guardando...' : 'Guardar Cambios'}
                    </Button>
                </Box>

                <Divider sx={{ my: 4 }} />

                <Typography variant="h6" gutterBottom sx={{ color: '#e55302' }}>
                    Cambiar Contraseña
                </Typography>

                <Box sx={{ mt: 2 }}>
                    <TextField
                        sx={{
                            mb: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                },
                                '&:hover fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: 'text.primary',
                            }
                        }}
                        fullWidth
                        type="password"
                        label="Nueva Contraseña"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        helperText="La contraseña debe tener al menos 6 caracteres"
                    />
                    <TextField
                        sx={{
                            mb: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                },
                                '&:hover fieldset': {
                                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: 'text.primary',
                            }
                        }}
                        fullWidth
                        type="password"
                        label="Confirmar Nueva Contraseña"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={newPassword !== confirmPassword && confirmPassword !== ''}
                        helperText={newPassword !== confirmPassword && confirmPassword !== '' ? 'Las contraseñas no coinciden' : ' '}
                    />
                </Box>

                {passwordMessage && (
                    <Box sx={{ 
                        mt: 2, 
                        color: passwordMessage.includes('Error') ? 'error.main' : 'success.main',
                        textAlign: 'center'
                    }}>
                        {passwordMessage}
                    </Box>
                )}

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                        onClick={handleChangePassword}
                        disabled={isChangingPassword || !newPassword || !confirmPassword || newPassword !== confirmPassword}
                        sx={{
                            bgcolor: '#e55302',
                            color: '#ffffff',
                            px: 4,
                            '&:hover': {
                                bgcolor: '#b23d00'
                            },
                            '&:disabled': {
                                bgcolor: 'rgba(229, 83, 2, 0.5)',
                                color: '#ffffff'
                            }
                        }}
                    >
                        {isChangingPassword ? 'Actualizando...' : 'Actualizar Contraseña'}
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default MiPerfil;
