import React, { createContext, useContext, useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';

const BrightnessContext = createContext();

const supabase = createClient(
  import.meta.env.VITE_TICKETS_SUPABASE_URL,
  import.meta.env.VITE_TICKETS_SUPABASE_ANON_KEY
);

export const BrightnessProvider = ({ children }) => {
  const [brightness, setBrightness] = useState(1);

  useEffect(() => {
    const checkMoraStatus = async () => {
      try {
        const { data, error } = await supabase
          .from('plataformas')
          .select('fecha_mora')
          .eq('id', 1)
          .single();

        if (error) throw error;

        if (data?.fecha_mora) {
          const fechaMora = new Date(data.fecha_mora);
          const hoy = new Date();
          const diasDesdeMora = Math.floor((hoy - fechaMora) / (1000 * 60 * 60 * 24));
          
          if (diasDesdeMora > 0) {
            // Reducir 1% por día, con un mínimo de 20% de brillo
            const nuevoBrillo = Math.max(1 - (diasDesdeMora * 0.01), 0.2);
            setBrightness(nuevoBrillo);
          }
        }
      } catch (error) {
        console.error('Error al verificar estado de mora:', error);
      }
    };

    checkMoraStatus();
    // Verificar cada hora
    const interval = setInterval(checkMoraStatus, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <BrightnessContext.Provider value={brightness}>
      <div style={{ 
        filter: `brightness(${brightness})`,
        transition: 'filter 0.3s ease',
        height: '100%'
      }}>
        {children}
      </div>
    </BrightnessContext.Provider>
  );
};

export const useBrightness = () => {
  const context = useContext(BrightnessContext);
  if (context === undefined) {
    throw new Error('useBrightness debe usarse dentro de un BrightnessProvider');
  }
  return context;
};
